<template>
  <span
    class="label font-weight-bold label-lg label-inline"
    v-bind:class="bindingClassLabel()"
    style="width: max-content"
  >{{ statusName }}</span>
</template>

<script>
import { STOCK_CHECK_SHEET_STATUS } from '@/utils/enum';

export default {
  props: ['status', 'statusName'],
  data() {
    return {
      STOCK_CHECK_SHEET_STATUS,
    };
  },
  methods: {
    bindingClassLabel() {
      return {
        'label-light-primary': this.status === STOCK_CHECK_SHEET_STATUS.NEW,
        'label-light-success': this.status === STOCK_CHECK_SHEET_STATUS.DONE,
        'label-light-warning':
          this.status === STOCK_CHECK_SHEET_STATUS.DIFFERENCE,
        'label-light-danger':
          this.status === STOCK_CHECK_SHEET_STATUS.STOCK_DEBT,
      };
    },
  },
  created() {},
};
</script>
