<template>
  <KTCodePreview v-bind:title="'Thông tin phiếu kiểm tồn kho'">
    <template v-slot:toolbar></template>
    <template v-slot:preview>
      <b-tabs pills v-model="tabIndex" card>
        <b-tab title="Kiểm kê" active :title-link-class="linkClass(0)">
          <hr
            class="hr-text"
            data-content="Thông tin phiếu"
            style="font-weight: 600"
          />
          <b-row class="mb-5">
            <b-col>
              <b-list-group>
                <b-list-group-item>
                  <b-row>
                    <b-col class="font-weight-bold" md="3">Mã phiếu:</b-col>
                    <b-col>{{ stockCheckSheet.code }}</b-col>
                  </b-row>
                </b-list-group-item>
                <b-list-group-item>
                  <b-row>
                    <b-col class="font-weight-bold" md="3">Cửa hàng:</b-col>
                    <b-col>{{ stockCheckSheet.storeName }}</b-col>
                  </b-row>
                </b-list-group-item>
                <b-list-group-item>
                  <b-row>
                    <b-col class="font-weight-bold" md="3"
                      >Loại sản phẩm:</b-col
                    >
                    <b-col>{{ stockCheckSheet.productTypeName }}</b-col>
                  </b-row>
                </b-list-group-item>
                <b-list-group-item>
                  <b-row>
                    <b-col class="font-weight-bold" md="3">Trạng thái:</b-col>
                    <b-col>
                      <StatusLabel
                        :status="stockCheckSheet.status || 1"
                        :statusName="stockCheckSheet.statusName"
                      />
                    </b-col>
                  </b-row>
                </b-list-group-item>
                <b-list-group-item>
                  <b-row>
                    <b-col class="font-weight-bold" md="3">Người tạo:</b-col>
                    <b-col>{{ stockCheckSheet.createdBy }}</b-col>
                  </b-row>
                </b-list-group-item>
                <b-list-group-item>
                  <b-row>
                    <b-col class="font-weight-bold" md="3">Ngày tạo:</b-col>
                    <b-col>{{ stockCheckSheet.createdAt }}</b-col>
                  </b-row>
                </b-list-group-item>
              </b-list-group></b-col
            >
            <b-col>
              <div class="note">
                <b-form-textarea
                  v-model="note"
                  id="textarea-small"
                  size="sm"
                  placeholder="Nhập ghi chú"
                ></b-form-textarea>
              </div>
            </b-col>
          </b-row>
          <InventoryCheckTable
            :stockCheckSheetData="stockCheckSheet"
            @onRefresh="fetchStockCheckSheet"
          >
            <b-button
              v-if="
                checkPermissions(['STOCK_CHECK_CONFIRM', 'STOCK_CHECK_SAVE'])
              "
              @click="confirmStockCheckSheet()"
              size="sm"
              variant="primary"
              class="font-weight-bolder mr-3"
              :disabled="isConfirmed && !checkPermissions('STOCK_CHECK_SAVE')"
            >
              Lưu phiếu
            </b-button>
          </InventoryCheckTable>
        </b-tab>
        <!-- <b-tab title="Thông tin" :title-link-class="linkClass(1)">
        </b-tab> -->
      </b-tabs>
    </template>
  </KTCodePreview>
</template>

<script>
import ApiService from '@/core/services/api.service';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import { formatDate } from '@/utils/common';
import StatusLabel from '@/view/components/inventoryCheck/StatusLabel.vue';
import InventoryCheckTable from '@/view/components/inventoryCheck/InventoryCheckTable.vue';
import KTCodePreview from '@/view/content/CodePreview.vue';
import { STOCK_CHECK_SHEET_STATUS } from '@/utils/enum';
import { makeToastSuccess, makeToastFaile } from '@/utils/common';
import {
  showConfirmDialog,
  showLoadingDialog,
  closeSwal,
  showSuccessNotification,
} from '@/utils/sweet-alert2';
import { checkPermission, checkPermissions } from '@/utils/saveDataToLocal';

export default {
  data() {
    return {
      STOCK_CHECK_SHEET_STATUS,
      stockCheckSheet: {},
      tabIndex: 0,
      stockCheckSheetId: null,
      note: '',
      isConfirmed: true,
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Danh sách phiếu kiểm tồn kho', route: '/inventory-check' },
      { title: 'Kiểm tra tồn kho' },
    ]);
    this.fetchStockCheckSheet();
  },
  created() {},
  computed: {},
  methods: {
    formatDate,
    checkPermission,
    checkPermissions,
    linkClass(idx) {
      if (this.tabIndex === idx) {
        return ['bg-primary', 'text-light', 'font-weight-bold'];
      } else {
        return ['bg-light', 'text-dark', 'font-weight-bold'];
      }
    },
    fetchStockCheckSheet: function () {
      const id = Number(this.$route.query.id) || this.$route.query.code;
      ApiService.get(`v2/stock-check-sheet/${id}`).then((res) => {
        const { note, ...info } = res.data.data;
        this.stockCheckSheet = info;
        this.isConfirmed = info.status !== STOCK_CHECK_SHEET_STATUS.NEW;
        this.note = note;
      });
    },
    savestockCheckSheet() {
      let params = {
        id: Number(this.id),
        totalItem: this.totalItem,
        note: this.note,
      };
      ApiService.put('stockCheckSheet/saveAndLock', params)
        .then((response) => {
          if (response.data.status) {
            makeToastSuccess(response.data.message);
            this.fetchstockCheckSheetItem();
          } else {
            makeToastFaile(response.data.message);
          }
        })
        .catch((error) => {
          if (error.response) {
            makeToastFaile(
              error.response.data ? error.response.data.message : 'Lỗi',
            );
          } else {
            makeToastFaile(error.message || 'Lỗi');
          }
        });
    },
    async confirmStockCheckSheet() {
      try {
        showConfirmDialog(
          'Lưu phiếu kiểm kê?',
          'Bạn có chắc muốn lưu phiếu kiểm kê. Phiếu sau khi được lưu sẽ không thể chỉnh sửa!',
        )
          .then(() => {
            showLoadingDialog(
              'Thao tác có thể mất vài phút',
              'Vui lòng chờ...',
            );
            return this.saveStockCheckSheet();
          })
          .catch(() => {});
      } catch (err) {
        const { message } = err.response.data || 'Lỗi!';
        makeToastFaile(message);
      }
    },

    async saveStockCheckSheet() {
      try {
        const dataUpdate = {
          id: this.stockCheckSheet.id,
          note: this.note || '',
        };
        const response = await ApiService.put(
          `v2/stock-check-sheet/${this.stockCheckSheet.id}`,
          dataUpdate,
        );
        const { message } = response.data;
        closeSwal();
        showSuccessNotification(message);
        this.fetchStockCheckSheet();
      } catch (err) {
        const { message } = err.response.data || 'Lỗi!';
        closeSwal();
        makeToastFaile(message);
      }
    },
  },
  components: {
    StatusLabel,
    KTCodePreview,
    InventoryCheckTable,
  },
};
</script>

<style>
.tab-status {
  background-color: ghostwhite;
}

.tab-status .nav .nav-link {
  font-weight: bold;
  font-size: 1.1rem;
  color: black;
}

.tab-status .nav .nav-link:hover {
  color: slateblue;
}

.tab-status .nav .nav-link.active {
  color: slateblue;
}

.input-style {
  border: 1px solid #ced4da;
}

.stock-info .card-body {
  padding: 1rem;
}

.form-group label {
  font-weight: 500;
}

.autosuggest__results ul {
  list-style: none;
  cursor: pointer;
  margin-bottom: 0;
}

.autosuggest__results {
  position: absolute;
  background-color: #fff;
  z-index: 1;
  width: 100%;
  border: 1px solid #e0e0e0;
  overflow: scroll;
  max-height: 200px;
  position: absolute;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
  z-index: 10000001;
  font-weight: 500;
  background: white;
}

#autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.35em + 1.1rem + 2px);
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.28rem;
}

#autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}

.icon-check {
  color: #1bc5bd !important;
  font-size: 25px;
}

.btn-update .btn.btn-sm i,
.btn-update .btn i {
  padding-right: 0;
}

.note textarea.form-control,
.note {
  height: 100%;
}

.autosuggest__results {
  position: absolute;
  width: 100%;
  max-height: 200px;
  border: 1px solid #e0e0e0;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: #fff;
}

.autosuggest__results-container {
  position: relative;
  font-weight: 500;
  width: 100%;
}

.autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.35em + 1.1rem + 2px);
  line-height: 1.35;
  padding: 0.55rem 0.75rem;
  font-weight: 400;
  font-size: 0.925rem;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.28rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 5px 8px 5px 8px;
}
</style>
